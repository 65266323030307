<template>
  <div>
    <el-form>
      <el-row :gutter="24">
        <el-col :md="16">
          <el-form-item class="flex" label="发布范围" required>
            <!-- <el-input readonly v-model="noticeReceiving"></el-input> -->
            <!-- <i class="el-icon-zoom-in" @click="getDialog"></i> -->
            <el-button type="primary" @click="getDialog" v-if="type !== 'view'">添加</el-button>
            <el-button type="primary" @click="delTable" v-if="type !== 'view'">删除</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-table :align="allAlign"
    @checkbox-all="selectAllEvent"
               @checkbox-change="selectChangeEvent"
               :data="value">
      <vxe-table-column type="checkbox"
                        width="50"></vxe-table-column>
      <vxe-table-column field="name" title="组织"></vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <modal  :modalConfig="{type:'Modal',visible:dialog.visible,title:'选择组织',width:'50%'}"
            @cancel="dialog_btnClose" @ok="dialog_btnConfirm" destroyOnClose @onClose="dialog_btnClose">
      <vxe-table border
                row-id="code"
                ref="xTable"
                class="vxeTable"
                @checkbox-change="dialog_checkboxChange"
                @checkbox-all="dialog_checkAll"
                :tree-config="{ children: 'children' }"
                :data="dialog.treeData"
                :checkbox-config="{checkRowKeys:dialog.checkRowKeys, checkStrictly: true, checkMethod:dialog_isCanCheck}"
                :expand-config="{expandRowKeys:dialog.checkRowKeys}">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column tree-node field="name" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </modal>
  </div>
</template>
<script>
import { Message } from 'element-ui';
import request from '../../../../../../utils/request';
import modal from '../../../../../../components/modal';

export default {
  props: {
    value: Array,
    type: String,
  },
  components: { modal },
  data() {
    return {
      noticeReceiving: '',
      allOrgCheck: false,
      modalConfig: {
        title: '组织',
        visible: false,
        type: 'Modal',
      },
      selectionList: [],
      checkedOrgLists: [],
      checkList: [],
      allAlign: null,
      checkStrictly: true,
      dialog: {
        visible: false, // 弹窗状态
        treeData: [], // 列表
        checkRowKeys: [], // 默认选中的
        selection: [], // 选中的
      },
    };
  },
  created() {
    this.dialog_getList();
  },
  watch: {
    value(val) {
      val.map((v) => {
        const item = v;
        this.dialog.checkRowKeys.push(item.code);
        return item;
      });
    },
  },
  methods: {
    /** @desc 单选框改变事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_checkboxChange({ records, checked, row }) {
      this.dialog.selection = [...records];
      if (checked) {
        records.map((v) => {
          const item = v;
          const index = this.dialog.checkRowKeys.findIndex((val) => val === item.code);
          if (index === -1) {
            const transver = (value) => {
              if (value.children) {
                this.$refs.xTable.setCheckboxRow(value, true);
                value.children.map((a) => {
                  const pItem = a;
                  this.dialog.selection.push(pItem);
                  this.$refs.xTable.setCheckboxRow(pItem, true);
                  transver(pItem);
                  return a;
                });
              }
            };
            transver(item);
          }
          return item;
        });
      } else {
        this.$refs.xTable.setCheckboxRow(row, false);
      }
    },
    /** @desc 全选事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_checkAll({ records }) {
      this.dialog.selection = [...records];
    },
    /** @desc 不允许选中的事件 */
    dialog_isCanCheck({ row }) {
      return !row.disabled;
    },
    /** @desc 按钮确认事件 */
    // eslint-disable-next-line consistent-return
    dialog_btnConfirm() {
      if (this.dialog.selection.length === 0) return Message.warning('请至少选择一条组织！');
      /** @todo
       * 后端需要的字段是wspCode,wspName
       * parentCode 有parentCode顶级组织（当前及下级组织），没有就是当前组织
       * 直接所有赋值，不需要数据合并和去重，因为此处不存在分页（此时，可坚固，取消已勾选的数据，外部列表数据同时也改变）
      */
      const selection = this.dialog.selection.map((item) => ({
        ...item, wspCode: item.code, wspName: item.name, currentAble: item.parentCode ? 'N' : 'Y',
      }));

      this.dialog.visible = false;
      this.$emit('input', selection);
    },
    /** @desc 按钮关闭事件 */
    dialog_btnClose() {
      this.dialog.checkRowKeys = [];
      this.dialog.selection = [];
      this.dialog.visible = false;
    },
    /** @desc 获取列表 */
    async dialog_getList() {
      try {
        const treeResult = await request.post('/mdm/baseTreeController/orgTree', {}, { headers: { functionCode: 'select-org' } });
        // const readyResult = await request.post('/sfa/sfaworksignrule/getAlreadySetOrgList');
        const treeData = treeResult ? treeResult.result : [];
        // let readyData = readyResult ? readyResult.result : [];
        const readyData = [];
        this.dialog_utilRecureData(treeData, readyData);
        this.dialog.treeData = treeData;
      } catch (error) { console.log(error); }
    },
    /** @desc 根据已绑定组织进行重设数据 */
    dialog_utilRecureData(treeData, readyData) {
      const recure = (arr) => {
        arr.forEach((row) => {
          const item = row;
          const find = readyData.find((v) => item.code === v.wspCode);
          item.disabled = !!find;
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            recure(item.children);
          }
        });
      };
      recure(treeData);
    },
    getDialog() {
      this.dialog.visible = true;
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
    delTable() {
      if (this.selectionList.length) {
        this.selectionList.map((v) => {
          const item = v;
          const index = this.value.findIndex((val) => val.id === item.id);
          if (index > -1) {
            this.value.splice(index, 1);
          }
          return this.value;
        });
      } else {
        this.$message.error('请选择数据');
      }
      this.$forceUpdate();
      this.$emit('input', this.value);
    },
    selectChangeEvent({ records }) {
      this.selectionList = records;
    },
    selectAllEvent({ records }) {
      this.selectionList = records;
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
  }
  /deep/ .el-form-item__label{
    width: 125px;
  }
  /deep/ .el-form-item__content{
    .flex;
    align-items: center;
  }
  .btn-delete{
    color:red;
    margin-left: 8px;
  }
  .vxeTable{
    height: 60vh;
  }
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
</style>
