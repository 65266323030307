<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import formTransfor from './components';

formCreate.component('formTransfor', formTransfor);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: true,
        close: true,
      },
    };
  },
  async created() {
    const rule = await this.getBeforFormRule('sfa_task_announcement_manage_add');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (this.formConfig.type === 'view') {
        v.props.disabled = true;
        this.buttons.submit = false;
      }
      if (v.field === 'noticeContent' && this.formConfig.type === 'view') {
        v.props.notView = true;
      }
      if (v.field === 'noticeReceivingReqVos') {
        newRule.push({
          type: 'formTransfor',
          field: 'noticeReceivingReqVos',
          title: '',
          value: [],
          props: {
            type: this.formConfig.type,
          },
        });
      } else {
        if (v.field === 'noticeFileReqVos') {
          v.props.showFileList = true;
          v.props.uploadType = 'file';
          v.props.listType = 'text';
          v.props.fileMsg = '只能上传pdf/word/excel/ppt/png/jpg文件';
          v.props.accept = '.pdf,.doc,.xls,.ppt,.png,.jpg,.jpeg,.xlsx,.docx,.pptx';
        }
        newRule.push(v);
      }
    });

    this.rule = newRule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .get('/mdm/mdmNoticeController/queryNoticeDetail', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            result.noticeReceivingReqVos = [];
            const noticeReceivingReqVos = [];
            result.noticeReceivingRespVos.map((v) => {
              const item = v;
              noticeReceivingReqVos.push({
                name: item.receivingName,
                code: item.receivingCode,
              });
              return item;
            });
            result.noticeFileReqVos = result.noticeFileRespVos ? result.noticeFileRespVos : [];
            result.noticeReceivingReqVos = noticeReceivingReqVos;
            this.setValue(result);
            this.row = res.result;
          }
        });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = JSON.parse(JSON.stringify(formData));
      param.noticeReceivingReqVos = [];
      formData.noticeReceivingReqVos.map((v) => {
        const item = v;
        param.noticeReceivingReqVos.push({
          receivingCode: item.code,
          receivingName: item.name,
        });
        return param;
      });
      if (param.noticeFileReqVos) {
        param.noticeFileReqVos.map((v) => {
          const item = v;
          if (!item.realPath) {
            item.realPath = item.urlPathPrefix;
          }
          return item;
        });
      }
      if (formData.beginDate.split(' ').length === 1) {
        param.beginDate = `${formData.beginDate} 00:00:00`;
      }
      if (formData.endDate.split(' ').length === 1) {
        param.endDate = `${formData.endDate} 23:59:59`;
      }
      if (new Date(param.beginDate).getTime() > new Date(param.endDate).getTime()) {
        this.$message.error('有效结束时间不能小于有效开始时间');
        return false;
      }
      if (param) {
        let url = '/mdm/mdmNoticeController/save';
        let params = { ...param };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmNoticeController/update';
          param.id = this.row.id;
          param.noticeCode = this.row.noticeCode;
          params = { ...param };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
