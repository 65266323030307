var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 16 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "发布范围", required: "" },
                    },
                    [
                      _vm.type !== "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getDialog },
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e(),
                      _vm.type !== "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.delTable },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vxe-table",
        {
          attrs: { align: _vm.allAlign, data: _vm.value },
          on: {
            "checkbox-all": _vm.selectAllEvent,
            "checkbox-change": _vm.selectChangeEvent,
          },
        },
        [
          _c("vxe-table-column", { attrs: { type: "checkbox", width: "50" } }),
          _c("vxe-table-column", { attrs: { field: "name", title: "组织" } }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            modalConfig: {
              type: "Modal",
              visible: _vm.dialog.visible,
              title: "选择组织",
              width: "50%",
            },
            destroyOnClose: "",
          },
          on: {
            cancel: _vm.dialog_btnClose,
            ok: _vm.dialog_btnConfirm,
            onClose: _vm.dialog_btnClose,
          },
        },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable",
              staticClass: "vxeTable",
              attrs: {
                border: "",
                "row-id": "code",
                "tree-config": { children: "children" },
                data: _vm.dialog.treeData,
                "checkbox-config": {
                  checkRowKeys: _vm.dialog.checkRowKeys,
                  checkStrictly: true,
                  checkMethod: _vm.dialog_isCanCheck,
                },
                "expand-config": { expandRowKeys: _vm.dialog.checkRowKeys },
              },
              on: {
                "checkbox-change": _vm.dialog_checkboxChange,
                "checkbox-all": _vm.dialog_checkAll,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              _c("vxe-table-column", {
                attrs: { "tree-node": "", field: "name", title: "组织名称" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }