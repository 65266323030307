import request from '../../../../utils/request';
import BasePage from '../../../../components/page_base';

export default {
  name: 'bug',
  extends: BasePage,
  data() {
    return {
      checkStrictly: true,
      formConfig: {},
      modalConfig: {
        // title: '新增',
        visible: false,
        type: '',
      },
      row: [],
      cellClickConfig: {
        functionCode: 'mdm_bug_form',
        queryUrl: '/mdm/mdmbuglistmanagement/query',
      },
    };
  },

  methods: {
    toggleCollapse(val) {
      if (val.collapse) {
        this.searchSpan = 24;
      } else {
        this.searchSpan = this.oldSpan;
      }
    },
    setSearchField(field) {
      return { ...field, span: 4 };
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.isUrgent === 'Y') {
        return 'warning-row';
      }
      return '';
    },
    /**
     * @override
     * 提交表单
     * @param formData
     */
    // onSubmit(formData) {
    //   console.log(this.row);
    //   if (formData) {
    //     const params = formData;
    //     const formItem = this.$refs.modalForm.getRule('conditionFieldList').options;
    //
    //     const newList = [];
    //     formItem.forEach((v) => {
    //       if (params.conditionFieldList.includes(v.dictCode)) {
    //         newList.push({
    //           fieldCode: v.dictCode,
    //           fieldName: v.dictValue,
    //         });
    //       }
    //     });
    //     params.conditionFieldList = newList;
    //
    //     let url = '/mdm/mdmPriceConditionGroupController/save';
    //
    //     if (this.modalConfig.config.code === 'edit') {
    //       url = '/mdm/mdmPriceConditionGroupController/update';
    //     }
    //
    //     request.post(url, params).then((res) => {
    //       if (res.success) {
    //         this.$message({
    //           type: 'success',
    //           message: '操作成功',
    //         });
    //
    //         this.closeModal();
    //         this.getList();
    //       }
    //     });
    //   }
    // },
    formatData(data) {
      return data;
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @param col
     * @returns {any}
     */
    // setColumn(col) {
    //   console.log(col);
    //   const coll = col;
    //   if (coll.col === 'feeBudgetCode') {
    //     Object.assign(col, {
    //       on: {
    //         change() {},
    //       },
    //     });
    //   } else if (coll.field === 'createDate') {
    //     coll.formatter = ({ row }) => `${row.createDate || ''} ${row.createDateSecond || ''}`;
    //   } else if (coll.field === 'updateDate') {
    //     coll.formatter = ({ row }) => `${row.updateDate || ''} ${row.updateDateSecond || ''}`;
    //   }
    //   return { ...coll };
    // },
    setRowBtn(btn) {
      // if(btn.code ===){

      // }
      return { ...btn };
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @returns {any}
     * @param field
     */
    // setFormField(field) {
    //   // if (this.modalConfig.config.code === 'edit') {
    //   //   this.modalConfig.title = '编辑';
    //   // } else if (this.modalConfig.config.code === 'add') {
    //   //   this.modalConfig.title = '新增';
    //   // } else if (this.modalConfig.config.code === 'view') {
    //   //   this.modalConfig.title = '查看';
    //   // }
    //   if (field.field === 'conditionGroupCode') {
    //     Object.assign(field, {
    //       props: {
    //         disabled: this.modalConfig.config.buttonCode !== 'add',
    //       },
    //       validate: [
    //         {
    //           pattern: '^[a-zA-Z0-9]*$',
    //           message: '条件字段分类编码仅支持输入英文和数字',
    //           trigger: 'blur',
    //         }, {
    //           required: true,
    //           message: '请输入条件字段分类编码',
    //           trigger: 'blur',
    //         },
    //       ],
    //     });
    //   }
    //   if (field.field === 'conditionFieldList') {
    //     Object.assign(field, {
    //       dictCode: 'price_condition',
    //       value: [],
    //       props: {
    //         ...field.props,
    //         valueKey: 'fieldCode',
    //       },
    //     });
    //   }
    //   return { ...field };
    // },
    /// 打开弹窗之前的方法
    async beforeModalClick({ val, row }, rule) {
      console.log(val);
      this.formConfig = { ...this.formConfig, code: val.buttonCode };
      if (val.buttonCode === 'add') {
        this.modalConfig.width = '60%';
        this.modalConfig.type = 'Drawer';
        this.modalConfig.title = '新增';
      }
      if (val.buttonCode === 'view') {
        // this.modalConfig.title = '查看';
        // this.modalConfig.width = '600px';
        // this.modalConfig.height = '260px';
        this.openZend(row.bugCode);
        return false;
      }
      // 加急
      if (val.buttonCode === 'expedited') {
        try {
          request.post(val.apiUrl, { ...row, isUrgent: 'Y' }).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        } catch (e) {
          this.$message.error(e);
        }
        return false;
      }
      this.row = row;
      return { val, row, rule };
    },

    // formLoadComplete() {
    // setTimeout(() => {
    //   this.$refs.modalForm.fApi.setValue({
    //     conditionFieldList: this.$refs.modalForm.fApi.getRule('conditionFieldList').value.map((v) => v.fieldCode),
    //   });
    // }, 100);
    // },
    // addForm() {
    //   this.disabled(true, ['conditionGroupDesc']);
    // console.log(this.configs.rule);
    // },

    openZend(code) {
      const url = `http://139.9.153.78:81/zentao/bug-view-${code}.html`;
      window.open(url, '_blank');
      // let a = document.querySelector('#openZend')
      // if (!a){
      //   a = document.createElement('a')
      //   a.id = 'openZend'
      //   a.target = '_blank'
      //   a.visible = 'hidden'
      // }
      // a.href = url
      // document.body.append(a)
      // a.click()
    },
  },
};
