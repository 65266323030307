<script>
import TablePage from '../../../../components/table_page';
import request from '../../../../utils/request';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmNoticeController/list',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('sfa_task_announcement_manage');
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'noticeTitle') {
        this.formConfig = {
          type: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = {};
      let urls = '';
      let text = '';
      if (val.code === 'add') {
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '新增';
        this.openFull();
      } else if (val.code === 'edit') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      } else if (val.code === 'delete') {
        urls = '/mdm/mdmNoticeController/delete';
        text = '删除';
        this.operationClick(urls, text);
      }
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>
